
import '@/assets/css/iframeStyle.scss';
import { Component, Vue, Watch } from 'vue-property-decorator';
import ChooseDialog from "../resourceManage/buy/component/ChooseDialog.vue";

@Component({
    name: "Trail",
    components: {
        ChooseDialog,
    },
})
export default class Trail extends Vue {
    private baseUrlIframe = process.env.VUE_APP_URL;
    private contentTitle: string = "我的试用";
    private selectedDBData: any = {
        appName: 'PostgreSQL',
    };
    private serviceItemData: any = {};
    private chooseFlag: boolean = false;
    private handleTryBuy() {
        this.serviceItemData = {
            id: localStorage.getItem("tryBuy_goodsId") + '',
        };
        // mc 4.0产品
        // this.serviceItemData = {
        //     id: '3191',
        // };
        this.chooseFlag = true;
    }
    // 选购成功开始接口认证
    private propChooseClose(value: any) {
        // const itemData: any = {
        //     dbType: 1,
        //     goodsId: 2363,
        //     orderDetailNumber: 1,
        //     orderDetailTimeNumber: 999,
        //     orderGoodsSceneType: 15,
        //     suiteName: "mc",
        // }
        this.chooseFlag = false;
        if (value.length > 0) {
            this.$router
            .push({
                path: '/company/orderConfirm',
                query: {
                    goodsData: JSON.stringify(value),
                },
            })
            .catch((err) => err);
        }
    }
}
